import { toast, UpdateOptions } from 'react-toastify';
import { ToastOptions } from 'react-toastify/dist/types';

import clsx from 'clsx';

import CheckIcon from '@/components/icons/common/CheckIcon.tsx';

import 'react-toastify/dist/ReactToastify.css';

export function notify(
  type: 'success' | 'info' | 'error' | 'warning' | 'update',
  message?: string,
  props?: ToastOptions & UpdateOptions,
) {
  if (type == 'update') {
    toast.update(props?.toastId || '', props);
    return;
  }

  return toast(message, {
    ...props,
    autoClose: props?.autoClose || 2500,
    className: clsx(
      props?.className,
      'pl-2 max-w-[90vw] rounded-xl mx-auto mt-4 font-6 text-neutral-700 text-14',
    ),
    closeButton: props?.closeButton !== undefined ? props.closeButton : false,
    icon: props?.icon || (type == 'success' ? <CheckIcon className="text-green-500" /> : undefined),
    progressClassName:
      props?.progressClassName || (type == 'success' ? 'bg-blue-500' : 'bg-blue-400'),
    type: type == 'success' ? 'success' : type,
  });
}
